import React from 'react'
import { ServicesView } from '@views'
import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'Services'} />
}

const Services = () => {
  return <ServicesView />
}

export default Services
